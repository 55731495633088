<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="list_loading" @change="handleTableChange">
    <a-table-column key="name" title="服务名称" align="center" data-index="name" width="200px" />
    <a-table-column key="type" title="类型" align="center" width="120px">
      <template slot-scope="record">
        <span>
          {{ record.type | ServiceManagerTypeText }}
        </span>
      </template>
    </a-table-column>
    <a-table-column key="creator" title="创建者" align="center" data-index="creator" width="120px" />
    <a-table-column key="createTimestamp" title="添加时间" data-index="createTimestamp" align="center" width="120px">
      <template slot-scope="createTimestamp">
        <span>
          {{ createTimestamp | moment('YYYY-MM-DD') }}
        </span>
      </template>
    </a-table-column>
    <a-table-column key="actions" title="操作" align="center" width="200px">
      <template slot-scope="record">
        <a-space>
          <a-button
            v-if="$auth('service_select')"
            type="link"
            size="small"
            @click="
              $router.push({
                path: '/service-manager/detail/' + record.id
              })
            "
            ><a-icon type="eye" />查看</a-button
          >
          <a-button
            v-if="$auth('service_update')"
            type="link"
            size="small"
            @click="
              $router.push({
                path: '/service-manager/edit/' + record.id
              })
            "
            ><a-icon type="edit" />编辑</a-button
          >
          <a-button
            v-if="$auth('service_delete')"
            type="link"
            size="small"
            @click="handleRemove(record)"
            class="error-color"
            ><a-icon type="delete" />删除</a-button
          >
        </a-space>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as serviceManagerApi from '@/api/serviceManager'
export default {
  data() {
    return {
      query: {},
      pagination: {},
      list_loading: false,
      list: []
    }
  },
  methods: {
    initData(query = {}) {
      const { initPagination, getData } = this
      this.query = query
      this.pagination = initPagination()
      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this
      this.list_loading = true
      return serviceManagerApi
        .list({ ...query, pageIndex, pageSize })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.list_loading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const element = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return element('div', {}, [
            element('span', '共 '),
            element('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            element('span', ' 页'),
            element('span', ' / '),
            element('span', { class: 'primary-color' }, total),
            element('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      getData()
    },
    handleRemove(record) {
      const confirm = this.$confirm({
        title: '删除',
        content: '您确定要删除吗',
        onOk: () => {
          return serviceManagerApi
            .remove(record.id)
            .then(() => {
              this.$notification['success']({ message: '操作成功' })
              this.getData()
            })
            .catch(err => {
              confirm.destroy()
              this.$info({
                title: '不可删除',
                content: err.msg
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
/deep/ .ant-btn {
  font-size: 12px;
}
</style>
