<template>
  <div>
    <a-card title="筛选查询" style="margin-bottom: 24px">
      <a-row :gutter="[24, 0]">
        <a-form-model class="ant-advanced-search-form">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="输入搜索：">
              <a-input-search
                v-model="query.name"
                allowClear
                placeholder="请输入服务名称"
                style="width: 100%"
                @search="initData"
              />
            </a-form-model-item>
          </a-col>
        </a-form-model>
        <a-form-model class="ant-advanced-search-form">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="类型：">
              <h-select v-model="query.type" allowClear placeholder="请选择类型" @change="() => initData()">
                <a-select-option v-for="typeInfo in typeOptions" :key="typeInfo.value" :value="typeInfo.value">
                  {{ typeInfo.label }}
                </a-select-option>
              </h-select>
            </a-form-model-item>
          </a-col>
        </a-form-model>
      </a-row>
    </a-card>
    <a-card title="服务列表" :body-style="{ padding: 0 }">
      <div slot="extra">
        <a-button
          v-if="$auth('service_add')"
          type="primary"
          shape="round"
          @click="$router.push('/service-manager/create')"
        >
          <a-icon type="plus" />新增服务
        </a-button>
      </div>
      <service-table ref="table" v-if="$auth('service_list')"></service-table>
    </a-card>
  </div>
</template>

<script>
import ServiceTable from './components/Table'
import { SERVICE_MANAGER_TYPE } from '@/store/dictionary'
export default {
  name: 'ServiceManagerList',
  components: {
    ServiceTable,
  },
  data() {
    return {
      query: {
        name: undefined,
        type: undefined,
      },
      typeOptions: SERVICE_MANAGER_TYPE,
    }
  },
  activated() {
    const { ready, initData } = this
    if (ready) {
      initData()
    }
  },
  mounted() {
    const { $nextTick, initData } = this
    $nextTick(() => {
      this.ready = true
    })
    initData()
  },
  methods: {
    initData() {
      this.$refs.table.initData(this.query)
    },
  },
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
